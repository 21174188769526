// Colors IoT
$brand-color-1: #222a43;
$brand-color-2: #384a8b;
$brand-color-3: #89c6cf;
$brand-color-4: #acdee5;
$brand-color-5: #c9e9ec;
$brand-color-6: #e6f3f4;
$brand-color-7: #e04e2e;

// Sidebar
$sidebar-color: #222a43;
$sidebar-brand-color: #b30000;

.home-icon {
  cursor: pointer;
}

.color-brand {
  background-color: $sidebar-brand-color !important;
}

.color-iot {
  background-color: $sidebar-color !important;
}

.list-item-parking {
  display: flex;
  justify-content: space-between;
}

.card-container {
  border-radius: 1rem;
}

.sidebar {
  background-color: $sidebar-color;
}

.title {
  margin-top: 4rem;
}

.background {
  background-color: #6b6767;

  &-dashboard {
    align-self: center;
  }
}

.negative {
  margin-left: -1.27rem;
  margin-right: -1.27rem;
  border-radius: 15px;
}

.padding-right {
  padding-right: 12px;
}

// Map

.h-map {
  height: 98% !important;
}

// Cards
// G Green - GL Green Light - Y Yellow - O Orange - R Red - F First - S Second - T Third
/** Don't try this at home */

.left-point{
  &-f-g{
    left: 37px !important;
  }
  &-s-g{
    left: 44px !important;
  }
  &-t-g{
    left: 48px !important;
  }
  &-f-gl{
    left: 58px !important;
  }
  &-s-gl{
    left: 68px !important;
  }
  &-t-gl{
    left: 78px !important;
  }
  &-f-y{
    left: 88px !important;
  }
  &-s-y{
    left: 98px !important;
  }
  &-t-y{
    left: 110px !important;
  }
  &-f-o{
    left: 125px !important;
  }
  &-s-o{
    left: 135px !important;
  }
  &-t-o{
    left: 145px !important;
  }
  &-f-r{
    left: 155px !important;
  }
  &-s-r{
    left: 165px !important;
  }
  &-t-r{
    left: 175px !important;
  }
}

.right-point{
  &-f-g{
    right: 165px !important;
  }
  &-s-g{
    right: 155px !important;
  }
  &-t-g{
    right: 145px !important;
  }
  &-f-gl{
    right: 135px !important;
  }
  &-s-gl{
    right: 125px !important;
  }
  &-t-gl{
    right: 115px !important;
  }
  &-f-y{
    right: 102px !important;
  }
  &-s-y{
    right: 92px !important;
  }
  &-t-y{
    right: 82px !important;
  }
  &-f-o{
    right: 70px !important;
  }
  &-s-o{
    right: 63px !important;
  }
  &-t-o{
    right: 54px !important;
  }
  &-f-r{
    right: 38px !important;
  }
  &-s-r{
    right: 28px !important;
  }
  &-t-r{
    right: 18px !important;
  }
}

.bar-color {
  &-g{
    background-color: rgb(1, 121, 1);
  }
  &-gl{
    background-color: rgb(5, 142, 5);
  }
  &-y{
    background-color: rgba(255, 255, 0, 0.653);
  }
  &-o{
    background-color: orange;
  }
  &-r{
    background-color: red;
  }
}

.progress {
  height: 1.27rem !important;
}

.gas-icon {
  width: 65px !important;
  height: 65px !important;
}

.removePadding {
  padding: 0.2rem;
}

.size-parking {
  font-size: 12px !important;
}

.card-align {
  align-self: center;

  &-icons {
    align-items: center;
  }
}

.body-padding {
  padding: 0.5rem;
}

.body-padding-negative {
  padding: 0 !important;

  > h5 {
    margin-block-end: 0 !important;
  }
}

.graph-card {
  border-radius: 0 !important;
  background-color: transparent !important;
  padding: 0;
}

.padding-graph {
  padding: 0;
}

// Fonts

.fonts {
  font-family: "Montserrat";
  font-weight: 700;

  &-sub {
    font-family: "GT Pressura";
    font-weight: 500;
  }
}

.styles-list {
  border: 0;
}

// Login

.index-bg {
  background-image: url("https://cdn.discordapp.com/attachments/780523152059924552/949004099221856396/loginbg.jpg");
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  z-index: -20;
}

.btn-radius {
  border-radius: 0.5rem;
  color: white;
  background-color: transparent;

  &:focus {
    @extend .btn-radius;
    box-shadow: 1px 0px 0px 1px rgb(255, 255, 255);
    color: white !important;
  }
}

.bg-login-icon {
  background-color: transparent !important;

  &:focus {
    @extend .bg-login-icon;
    background-color: transparent !important;
    color: white !important;
    border-color: white !important;
  }
}

.bg-login {
  background-color: transparent !important;
  border: 0 !important;
  border-radius: 0 !important;

  @include media-breakpoint-down(md) {
    .md-bg-login {
      width: 100% !important;
    }
  }
}

.large-btn {
  padding-left: 3rem;
  padding-right: 3rem;
}

.center-form {
  text-align: -webkit-center;
}

.hoverIcon {
  cursor: pointer;
}

// Reports

.report-embed {
  height: 100%;
  width: 100%;
  background-color: white;
}

// Override MUI

.MuiTablePagination-displayedRows {
  margin-top: unset !important;
  margin-bottom: unset !important;
}

.MuiTablePagination-selectLabel {
  margin: 0 !important;
}

.MuiPaper-root {
  margin-bottom: 0 !important;
}
